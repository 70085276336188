<template>
  <b-form>
    <b-row>
      <b-col cols="12" md="4">
        <b-form-group label="Account Holder Name" label-for="full-name">
          <b-form-input id="full-name" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-group label="Account Number" label-for="full-name">
          <b-form-input id="full-name" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4">
        <b-form-group label="Blank Name" label-for="full-name">
          <b-form-input id="full-name" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-group label="IfCS Code" label-for="full-name">
          <b-form-input id="full-name" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-group label="Pan Number" label-for="full-name">
          <b-form-input id="full-name" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-group label="Branch" label-for="full-name">
          <b-form-input id="full-name" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-2">
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          <span> حفظ </span>
        </b-button>
        <b-button
          variant="outline-secondary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          <span> إعادة تعيين </span>
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
  },
  setup() {
    const userDataSocial = {
      twitter: "https://www.twitter.com/adoptionism744",
      facebook: "https://www.facebook.com/adoptionism664",
      instagram: "https://www.instagram.com/adopt-ionism744",
      github: "https://www.github.com/madop818",
      codepen: "https://www.codepen.com/adoptism243",
      slack: "@adoptionism744",
    };

    // ? dataField is field name of `userDataSocial` object
    const socialInputs = [
      {
        icon: "TwitterIcon",
        dataField: "twitter",
        label: "Twitter",
      },
      {
        icon: "FacebookIcon",
        dataField: "facebook",
        label: "facebook",
      },
      {
        icon: "InstagramIcon",
        dataField: "instagram",
        label: "instagram",
      },
      {
        icon: "GithubIcon",
        dataField: "github",
        label: "github",
      },
      {
        icon: "CodepenIcon",
        dataField: "codepen",
        label: "codepen",
      },
      {
        icon: "SlackIcon",
        dataField: "slack",
        label: "slack",
      },
    ];

    return {
      userDataSocial,
      socialInputs,
    };
  },
};
</script> 

<style></style>