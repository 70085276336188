<template>
  <div>
    

    <b-tabs  pills>
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">المعلومات الشخصية</span>
        </template>
        <!-- <user-edit-tab-account
          :user-data="userData"
          class="mt-2 pt-75"
        /> -->
        <user-edit-tab-info class="mt-2 pt-75"/>
      </b-tab>

      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon icon="FileIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">الوثائق</span>
        </template>
        <user-edit-tab-document class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Social -->
      <b-tab>
        <template #title>
          <feather-icon icon="LinkIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">الحساب</span>
        </template>
        <user-edit-tab-bank class="mt-2 pt-75" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import userStoreModule from "../userStoreModule";
import UserEditTabInfo from "./UserEditTabInfo.vue";
import UserEditTabDocument from "./UserEditTabDocument.vue";
import UserEditTabBank from "./UserEditTabBank.vue";
export default {
  setup() {
    const userData = ref(null);

    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    // store
    //   .dispatch("app-user/fetchUser", { id: router.currentRoute.params.id })
    //   .then((response) => {
    //     //console.log("ji",response)
    //     userData.value = response.data;
    //   })
    //   .catch((error) => {
    //     if (error.response.status === 404) {
    //       userData.value = undefined;
    //     }
    //   });

    return {
      userData,
    };
  },

  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    UserEditTabInfo,
    UserEditTabDocument,
    UserEditTabBank,
  }
};
</script>

<style></style>